import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaPaginaGenerica from "../components/page-content/fascia-pagina-generica";
import useInitDataLayer from "../utility/useInitDataLayer";
import { useHandleRecaptchaBadge } from "../utility/useHandleRecaptchaBadge";

const IndexPage = ({ data, location }) => {
  const { title, headerType, footerType, showSmartBannerApp, seo, fasce, impressionDetail } =
    data.page;
  useInitDataLayer(location, impressionDetail);
  useHandleRecaptchaBadge(fasce);
  return (
    <Layout
      headerType={headerType?.value?.[0]}
      footerType={footerType?.value?.[0]}
      banner={showSmartBannerApp?.value}
      location={location}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path="/"
      />
      <div>
        {fasce?.map((fascia, i) => (
          <FasciaPaginaGenerica
            fascia={fascia.value}
            key={i}
            location={location}
            title={seo?.content?.htmlTitle?.value || title?.value}
          />
        ))}
      </div>
    </Layout>
  );
};
export default IndexPage;
export const query = graphql`
  query {
    page: liferayJskLayoutPaginaGenerica(friendlyUrl: { value: { eq: "/" } }) {
      liferayFields {
        siteId
        articleId
      }
      headerType {
        value
      }
      footerType {
        value
      }
      impressionDetail {
        value
      }
      showSmartBannerApp {
        value
      }
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      fasce {
        value {
          ...FasciaPaginaGenericaFragment
        }
      }
    }
  }
`;
